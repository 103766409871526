import request from '@/utils/request';

// 列表
export function List(params) {
    return request({
        url: '/admin/custom/company/page',
        method: 'GET',
        params
    });
}



